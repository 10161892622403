import logo from "./vegard.png";
import "./App.css";
import drumSound from "./drum.mp3";

function App() {
  let audio = new Audio(drumSound);
  const start = () => {
    audio.play();
    console.log("🥁");
  };
  return (
    <div className="App" onClick={start}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Jeg er Vegard, ordspillkongen!</h1>
        <p>
          <i>
            "Dette legger jeg meg ikke oppi, sa mannen da kjerringa dreit i
            senga"
          </i>
        </p>
        <a href="mailto:vegvon@gmail.com">
          <p>
            Ta kontakt for en uforpliktende prat og tilbud på et godt ordspill!
          </p>
        </a>
      </header>
    </div>
  );
}

export default App;
